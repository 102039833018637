import { POSITION, useToast } from "vue-toastification";
import { useStore } from "vuex";
import { EstateInterface } from "@/types/EstateInterface";
import { Ref } from "vue";

const getEstatesByLandowner = (
  landownerId: string,
  estates: Ref<EstateInterface[]>
): void => {
  const store = useStore();
  const toast = useToast();

  if (landownerId) {
    store
      .dispatch("estate/getEstates", { landownerId })
      .then((res) => {
        estates.value = res.data;
      })
      .catch((error) => {
        toast.error("Error getting estates", { position: POSITION.TOP_LEFT });
      });
  }
};

export default getEstatesByLandowner;
