import {
  LotStatusByReportWithCount,
  LotStatusByReportWithTotalPrice,
  LotStatusCounts,
} from "@/types/AnalyticsLotStatusInterface";
import { LotStatus } from "@/enums/LotStatus";

const lotStatusArray = [
  LotStatus.NOT_RELEASED,
  LotStatus.AVAILABLE,
  LotStatus.HOLD,
  LotStatus.PRE_CONTRACT,
  LotStatus.UNDER_CONTRACT,
  LotStatus.UNCONDITIONAL,
  LotStatus.SETTLED,
  LotStatus.OPTION,
  LotStatus.OFFLINE,
];

export function getUniqueLotStatusArrayWithCount(
  lotsStatusByReport: LotStatusByReportWithCount[]
): string[] {
  const uniqueSet = new Set<string>();
  for (const lot of lotsStatusByReport) {
    uniqueSet.add(lot.status);
  }
  return Array.from(uniqueSet);
}

export function getTotalByLotStatusArrayWithCount(
  lotsStatusByReport: LotStatusByReportWithCount[],
  uniqueLotStatusArray: string[]
): number[] {
  const result = [];
  const lotStatusCounts: LotStatusCounts = {};

  for (const lot of lotsStatusByReport) {
    const { status, count } = lot;
    if (uniqueLotStatusArray.includes(status)) {
      lotStatusCounts[status] = (lotStatusCounts[status] ?? 0) + count;
    }
  }

  for (const lotStatus of uniqueLotStatusArray) {
    result.push(lotStatusCounts[lotStatus] || 0);
  }

  return result;
}

export function getUniqueLotStatusArrayWithTotalPrice(
  lotsStatusByReport: LotStatusByReportWithTotalPrice[]
): string[] {
  const uniqueSet = new Set<string>();
  for (const lot of lotsStatusByReport) {
    uniqueSet.add(lot.lotStatus);
  }
  return Array.from(uniqueSet);
}

export function getTotalByLotStatusArrayWithTotalPrice(
  lotsStatusByReport: LotStatusByReportWithTotalPrice[],
  uniqueLotStatusArray: string[]
): number[] {
  const result = [];
  const lotStatusCounts: LotStatusCounts = {};

  for (const lot of lotsStatusByReport) {
    const { lotStatus, lotPrice } = lot;
    if (uniqueLotStatusArray.includes(lotStatus)) {
      lotStatusCounts[lotStatus] = (lotStatusCounts[lotStatus] ?? 0) + lotPrice;
    }
  }

  for (const lotStatus of uniqueLotStatusArray) {
    result.push(lotStatusCounts[lotStatus] || 0);
  }

  return result;
}

export function getTotalDataLots(totalByLotStatusArray: number[]): number {
  return totalByLotStatusArray.reduce((acc, count) => acc + count, 0);
}

export function getSortedUniqueEstateTitle(
  lotsStatusByReport: (
    | LotStatusByReportWithCount
    | LotStatusByReportWithTotalPrice
  )[]
): string[] {
  const uniqueArray = Array.from(
    new Set([...lotsStatusByReport.map((lot) => lot.estateTitle)])
  );
  const sortedArray = uniqueArray.sort((a, b) => parseFloat(a) - parseFloat(b));
  return sortedArray.map(String);
}

export function getStatusByEstate(
  lotsStatusByReport: (
    | LotStatusByReportWithCount
    | LotStatusByReportWithTotalPrice
  )[],
  sortedUniqueEstateTitle: string[],
): { estate: string; count: number[]; total: number }[] {
  const statusByEstate = [];

  for (const estate of sortedUniqueEstateTitle) {
    const counts = [];
    for (const status of Object.values(lotStatusArray)) {
      let count = 0;
      for (const lot of lotsStatusByReport) {
        if (lot.estateTitle === estate) {
          if ("count" in lot && lot.status === status) {
            count += lot.count;
          } else if ("lotPrice" in lot && lot.lotStatus === status) {
            count += lot.lotPrice;
          }
        }
      }
      counts.push(count);
    }
    const total = counts.reduce((acc, curr) => acc + curr, 0);
    statusByEstate.push({
      estate: estate,
      count: counts,
      total: total,
    });
  }

  return statusByEstate;
}
