import { HoldsReportInterface } from "@/types/AnalyticsHoldsReportInterface";

export function filterByPeriod(
  data: HoldsReportInterface[],
  startDate: Date | string,
  endDate: Date | string
): HoldsReportInterface[] {
  return data.filter((hold: HoldsReportInterface) => {
    const createdAt = new Date(hold.createdAt);
    const startDateSelected = new Date(startDate);
    const endDateSelected = new Date(endDate);

    return createdAt >= startDateSelected && createdAt <= endDateSelected;
  });
}
